<div class="form-group {{hasError && submitted? 'is-invalid': ''}} dropdown-with-input full-width">
  <label>{{title | translate}}</label>
  <div ngbDropdown class="dropdown-without-label full-width">
    <button class="border-light-red btn btn-outline-gray dropdown-toggle-right full-width smaller-padding main-button" ngbDropdownToggle>
      <span class="dropdown-title-text">{{ selectedOption ? selectedOption : '' }}</span><span class="custom-toggle-arrow"></span>
    </button>
    <div ngbDropdownMenu class="dropdown-menu-custom full-width">
      <button class="dropdown-item full-width" style="justify-content: end" *ngFor="let option of options" (click)="chooseOption(option)">
        {{ option }}
      </button>
      <div class="dropdown-divider full-width"></div>
      <input type="text" class="form-control full-width mb-1" placeholder="{{'PLACEHOLDER' | translate}}" [(ngModel)]="customInput" />
      <button class="choose-input-button btn btn-outline-gray" (click)="addCustomOption()">
        {{'CHOOSE_INPUT' | translate}}
      </button>
    </div>
  </div>
</div>
