import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

@Component({
  selector: 'app-dropdown-with-input',
  templateUrl: './dropdown-with-input.component.html',
  styleUrls: ['./dropdown-with-input.component.scss']
})
export class DropdownWithInputComponent implements OnChanges {
  @Input() options: string[];
  @Output() selectOption: EventEmitter<string> = new EventEmitter<string>();
  @Input() hasError: boolean;
  @Input() submitted: boolean;
  @Input() disabled?: boolean = false;
  @Input() title: string;
  @Input() selectedOption: string | null = null;
  customInput: string = '';

  ngOnChanges(): void {
    if (this.selectedOption && !this.options.find(opt => opt === this.selectedOption)) {
      this.options.push(this.selectedOption);
    }
  }

  chooseOption(option: string) {
    this.selectedOption = option;
    this.customInput = '';
    this.selectOption.emit(option);
  }

  addCustomOption() {
    if (this.customInput.trim()) {
      const input = this.customInput.trim();
      this.options.push(input);
      this.selectedOption = input;
      this.customInput = '';
      this.selectOption.emit(input);
    }
  }
}
