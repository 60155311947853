import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  private languageOptions = [
    {
      'title': 'English',
      'id': 'en'
    },
    {
      'title': 'Dutch',
      'id': 'nl'
    }];

  public shouldShowLanguagePicker = false;

  constructor(private _translateService: TranslateService,
              private _localStorageService: LocalStorageService) {
  }

  setLanguage(language?): void {
    if (language === this._localStorageService.getLanguage() || !language) {
      return;
    }
    language = this.getLanguageById(language);
    if (!language) {
      return;
    }
    this._translateService.use(language.id);
    this._localStorageService.setLanguage(language.id);
  }

  private getLanguageById(id: string) {
    return this.languageOptions.filter(lang => lang.id === id)[0];
  }

  getSelectedLanguage() {
    return this.languageOptions.filter(lang => lang.id === this._localStorageService.getLanguage())[0];
  }

  getLanguageOptions() {
    return this.languageOptions;
  }
}
