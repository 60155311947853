import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';

import {CoreCommonModule} from '@core/common.module';

import {DropdownComponent} from './components/dropdown/dropdown.component';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {MultipleSelectComponent} from './components/multipleselect/multiple-select/multiple-select.component';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {DatepickerComponent} from './components/datepicker/datepicker.component';
import {InputFilterDropdownComponent} from './components/input-filter-dropdown/input-filter-dropdown.component';
import {TwoDigitDecimalPositiveNumberDirective} from './directives/two-digit-decimal-positive-number.directive';
import {ValidationMessageComponent} from './components/validation-message/validation-message.component';
import {DropdownWithInputComponent} from './components/dropdown-with-input/dropdown-with-input.component';

const routes: Routes = [];

@NgModule({
  declarations: [
    DropdownComponent,
    MultipleSelectComponent,
    ConfirmModalComponent,
    DatepickerComponent,
    TwoDigitDecimalPositiveNumberDirective,
    InputFilterDropdownComponent,
    ValidationMessageComponent,
    DropdownWithInputComponent
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    RouterModule.forChild(routes),
    TranslateModule
  ],
  exports: [
    DropdownComponent,
    MultipleSelectComponent,
    ConfirmModalComponent,
    TranslateModule,
    TwoDigitDecimalPositiveNumberDirective,
    InputFilterDropdownComponent,
    DatepickerComponent,
    ValidationMessageComponent,
    DropdownWithInputComponent
  ],

  providers: [CookieService]
})
export class SharedModule {
}
