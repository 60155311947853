import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {ChangePasswordModel} from '../models/auth/change-password';
import {UserProfileInfo} from '../models/user/profile-info';
import {tap} from 'rxjs/operators';
import {RegistrationInfo} from '../models/user/registrationInfo';
import {UnverifiedUser} from '../models/user/unverified-user';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public profilePicture: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public userIdForCitizen: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(private http: HttpClient) {
  }

  public getUserProfile(): Observable<UserProfileInfo> {
    return this.http.get<UserProfileInfo>('/api/users/profile')
      .pipe(
        tap(userProfile =>
          this.profilePicture.next(userProfile.imageSrc)
        )
      );
  }

  public updateUserProfileInfo(userProfileInfo: UserProfileInfo): Observable<UserProfileInfo> {
    return this.http.put<UserProfileInfo>('/api/users/edit-profile', userProfileInfo);
  }

  public updateChangePassword(changePasswordDto: ChangePasswordModel): Observable<UserProfileInfo> {
    return this.http.post<any>('/api/users/change-password', changePasswordDto);
  }

  public citizenRegister(account: RegistrationInfo): Observable<number> {
    return this.http.put<number>('/api/users/register-citizen', account);
  }

  public fetchDataForUnverifiedUser(): Observable<UnverifiedUser> {
    return this.http.get<UnverifiedUser>('/api/users/unverified/pre-populate-data');
  }

  public verifyMail(code: string): Observable<any> {
    return this.http.get<any>(`/api/users/citizen/verify-mail/${code}`);
  }

  public resendMail(): Observable<void> {
    return this.http.get<void>(`/api/users/citizen/send-mail/${this.userIdForCitizen.value}`);
  }
}
