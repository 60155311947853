import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {Observable} from 'rxjs';
import {LoginModel} from '../models/auth/login';
import {TokenModel} from '../models/auth/token';
import {RequestResetPassword} from '../models/auth/request-reset-password';
import {ResetPassword} from '../models/auth/reset-password';
import {Role} from '../models/user/role';
import {CookieService} from 'ngx-cookie-service';
import {PlatformConfigurationService} from './platform-configuration.service';

const BASE_URL = '/api/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loginOccurred = new EventEmitter<any>();

  constructor(private http: HttpClient,
              private localStorageService: LocalStorageService,
              private platformConfigurationService: PlatformConfigurationService,
              private cookieService: CookieService) {
  }

  login(loginData: LoginModel): Observable<TokenModel> {
    return this.http.post<TokenModel>(BASE_URL, loginData);
  }

  logout() {
    this.localStorageService.clearUserData();
    this.cookieService.deleteAll();
  }

  saveUserInfo(token: string, roles: string[]) {
    this.localStorageService.setToken(token);
    this.localStorageService.setRoles(roles);
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  getToken(): string {
    return this.localStorageService.getToken();
  }

  getUsername(): string {
    const sub = this?.decodeToken()?.sub;
    if (sub != null)
      return sub;
    const username = this?.decodeToken()?.username;
    if (username != null)
      return username;
    return ''
  }

  getRoles(): string[] {
    return this.localStorageService.getRoles();
  }

  isSuperAdmin(): boolean {
    if (!this.isLoggedIn()) return false;
    return this.getRoles().includes(Role.SUPERADMIN_SCHAARBEEK);
  }

  isLocalAdmin(): boolean {
    if (!this.isLoggedIn()) return false;
    return !this.getRoles()?.includes(Role.SUPERADMIN_SCHAARBEEK) && this.getRoles().includes(Role.LOCAL_ADMIN);
  }

  isAcademieAdmin(): boolean {
    if (!this.isLoggedIn()) return false;
    return this.getRoles()?.includes(Role.SUPERADMIN_SCHAARBEEK) || this.getRoles().includes(Role.LOCAL_ADMIN);
  }

  isMagazineAdmin(): boolean {
    if (!this.isLoggedIn()) return false;
    return this.getRoles().includes(Role.EMAGAZINE_ADMIN);
  }

  isApprover(): boolean {
    if (!this.isLoggedIn()) return false;
    return this.getRoles().includes(Role.APPROVER_FREE_CLB);
  }

  isAdministrativeRole(): boolean {
    return this.isLoggedIn() && (this.isAcademieAdmin() || this.isApprover() || this.isMagazineAdmin());
  }

  isParticipant(): boolean {
    return this.isLoggedIn() &&
      (this.getRoles().includes(Role.PARTICIPANT_FREE) || this.getRoles().includes(Role.CLB_EXTERNAL_PARTICIPANT) || this.getRoles().includes(Role.CITIZEN));
  }

  isAcademie(): boolean {
    if (!this.isLoggedIn() || !this.getRoles()) return false;
    let hasRole = false;
    this.platformConfigurationService.getAcademieRoles().forEach(role => {
      if (this.getRoles().includes(role)) hasRole = true;
    })
    return hasRole;
  }

  isMagazine(): boolean {
    if (!this.isLoggedIn()) return false;
    let hasRole = false;
    this.platformConfigurationService.getMagazineRoles().forEach(role => {
      if (this.getRoles().includes(role)) hasRole = true;
    })
    return hasRole;
  }

  currentUserHasRole(role: string): boolean {
    if (this.localStorageService.getRoles()) {
      return this.getRoles().includes(Role[role]);
    }
    return true;
  }

  decodeToken(): any {
    if (this.getToken() == null) return null
    const jwtData = this.getToken().split('.')[1];
    const jwtFormattedData = jwtData ? jwtData.replace('-', '+').replace('_', '/') : null;
    const decodedJwtJsonData = atob(jwtFormattedData);
    return JSON.parse(decodedJwtJsonData);
  }

  public requestResetPassword(requestResetPassword: RequestResetPassword) {
    return this.http.post<void>(BASE_URL + '/reset-password', requestResetPassword);
  }

  public resetPassword(password: ResetPassword, linkHash: string) {
    return this.http.post<void>(BASE_URL + '/reset-password/' + linkHash, password);
  }

  notifyLoginOccurred() {
    this.loginOccurred.emit()
  }

  isExternalUser(): boolean {
    if (!this.isLoggedIn())
      return false;
    return this.getRoles().includes(Role.CLB_EXTERNAL_PARTICIPANT);
  }

  setLastLogin(lastLogin: Date) {
    this.localStorageService.setLastLogin(lastLogin);
  }

  getLastLogin(): Date {
    return this.localStorageService.getLastLogin();
  }

  isUnverified(): boolean {
    return this.getRoles().includes(Role.UNVERIFIED_USER);
  }

  isCitizen(): boolean {
    if (!this.isLoggedIn())
      return false;
    return this.getRoles().includes(Role.CITIZEN);
  }

  hasExternalRole(): boolean {
    return this.isExternalUser() || this.isCitizen();
  }

}
